import {
  Avatar,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { LogOut, NavArrowDown } from "iconoir-react"
import React, { useContext } from "react"
import { Link, NavLink } from "react-router-dom"
import { clearAuthInfo } from "utils/auth"
import { getPlatformURL } from "utils/helpers"

import { routes } from "../utils/routes"
import { GlobalContext } from "./GlobalState"
import PageContainer from "./PageContainer"
import BackButton, { BackButtonProps } from "./shared/BackButton"
import TruncatedText from "./TruncatedText"

export type NavbarProps = {
  slotEnd?: React.ReactElement
  bottomEndSlot?: React.ReactElement
  className?: string
  gutterBottom?: boolean
  hideBackButton?: boolean
  backButtonProps?: BackButtonProps
  hideBanner?: boolean
  startSlot?: React.ReactElement
}

const Navbar = ({
  slotEnd,
  className,
  gutterBottom = true,
  startSlot,
  backButtonProps,
  hideBackButton,
}: NavbarProps) => {
  const { profile } = useContext(GlobalContext)

  const fullName = [profile.firstName, profile.lastName]
    .filter(Boolean)
    .join(" ")

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom,
        })}
      >
        <nav
          className={clsx(
            "flex h-[62px] max-h-[62px] items-center border border-b-onSurface-200 bg-common-white-500 py-1",
            className
          )}
        >
          <PageContainer className="w-full">
            <div
              className={
                "flex w-full flex-wrap items-center justify-between md:flex-nowrap"
              }
            >
              {/* Start */}
              <div className="flex gap-1">
                <div>
                  <div className="flex items-center gap-2">
                    <Link to={routes.home}>
                      <img
                        src="https://assets.suraasa.com/logos/v2/learning-operations.webp"
                        alt=""
                        height="45"
                        width="45"
                      />
                    </Link>
                  </div>
                </div>

                {startSlot}
              </div>
              {/* Middle */}

              <div className="hidden items-center gap-4 sm:flex">
                <NavLink
                  className="text-inherit no-underline"
                  to={routes.assignment.list}
                >
                  <Typography variant="strong">Assignments</Typography>
                </NavLink>
                <NavLink
                  className="text-inherit no-underline"
                  to={routes.students.list}
                >
                  <Typography variant="strong">Students</Typography>
                </NavLink>
                <NavLink
                  className="text-inherit no-underline"
                  to={routes.classes.home}
                >
                  <Typography variant="strong">Classes</Typography>
                </NavLink>
              </div>

              {/* End */}
              <div className="flex items-center gap-1">
                {slotEnd && <div>{slotEnd}</div>}

                <div className="flex items-center gap-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="text" className="text-onSurface-900">
                        <span className="flex items-center gap-1">
                          <Avatar name={fullName} className="size-5" />
                          <div className="hidden text-start capitalize md:block">
                            <TruncatedText maxLength={30} variant="strong">
                              {fullName}
                            </TruncatedText>
                            <Typography variant="smallBody">
                              Suraasa Professor
                            </Typography>
                          </div>
                          <NavArrowDown className={clsx("hidden md:block")} />
                        </span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuGroup>
                        <DropdownMenuItem
                          color="critical"
                          startAdornment={<LogOut />}
                          onClick={() => {
                            clearAuthInfo()
                            const url = new URL(
                              getPlatformURL(
                                "sso",
                                "/logout?platform=Professor"
                              )
                            )
                            url.searchParams.append(
                              "origin",
                              `${window.location.origin}`
                            )
                            window.location.href = url.href
                          }}
                        >
                          Logout
                        </DropdownMenuItem>
                      </DropdownMenuGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </PageContainer>
        </nav>
        {!hideBackButton && (
          <div className="my-2">
            <PageContainer>
              <div className="flex items-center justify-between">
                <BackButton {...backButtonProps} />
              </div>
            </PageContainer>
          </div>
        )}
      </div>
    </>
  )
}

export default Navbar
