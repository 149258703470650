import * as Sentry from "@sentry/react"
import { ToastProvider } from "@suraasa/placebo-ui"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { APIError } from "api/utils"
import GlobalState from "components/GlobalState"
import { PageWithMeta } from "components/Metadata"
import PrivateRoute from "components/PrivateRoute"
import ViewStudent from "features/Assignments/Assignment/ViewStudent"
import ViewSubmission from "features/Assignments/Check"
import ViewAssignment from "features/Assignments/ViewAssignment"
import SSO from "features/auth/SSO"
import Home from "features/home"
import ListStudentsOrAssignments, {
  Type,
} from "features/home/ListStudentsOrAssignments"
import Lectures from "features/Lectures"
import ViewLecture from "features/Lectures/ViewLecture"
import React from "react"
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import DynamicRedirect from "utils/dynamicRedirects"
import { oldRoutes, routes } from "utils/routes"

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

if (SENTRY_DSN) {
  Sentry.init({
    ignoreErrors: [],
    release: `${process.env.VITE_SENTRY_PROJECT}@` + APP_VERSION,
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.2,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [[500, 599]],
      }),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    sendDefaultPii: true,

    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,

    beforeSend(event, hint) {
      // console.log("> Logging event on Sentry", event, hint)

      // Hydrating APIErrors with more information so it's easier to debug on Sentry Dashboard
      if (event.exception?.values && event.exception.values.length > 0) {
        const exception = event.exception.values[0]
        if (exception?.type === "APIError") {
          const originalException = hint.originalException as APIError
          event.tags = {
            ...event.tags,
            APIError: true,
          }

          let body = originalException?.__errors?.config?.data || null
          try {
            body = JSON.parse(originalException?.__errors?.config?.data)
          } catch (e) {
            // Do nothing
          }

          event.contexts = {
            ...event.contexts,
            API: {
              url: originalException?.__errors?.config?.url,
              body,
              method: originalException?.__errors?.config?.method,
              statusCode: originalException?.statusCode,
              ...originalException?.errors,
            },
          }

          return event
        }
      }

      return event
    },
  })

  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  })

  const sentryCreateBrowserRouter = SENTRY_DSN
    ? Sentry.wrapCreateBrowserRouterV6(createBrowserRouter)
    : createBrowserRouter

  const router = sentryCreateBrowserRouter([
    {
      path: routes.root,
      element: <SSO />,
    },
    {
      path: routes.root,
      element: <PrivateRoute />,
      children: [
        { path: routes.home, element: <Home /> },
        {
          path: routes.assignment.list,
          element: (
            <PageWithMeta name={"assignments"}>
              <ListStudentsOrAssignments type={Type.assignment} />
            </PageWithMeta>
          ),
        },
        {
          path: routes.students.list,
          element: (
            <PageWithMeta name={"students"}>
              <ListStudentsOrAssignments type={Type.student} />
            </PageWithMeta>
          ),
        },
        {
          path: routes.assignment.view,
          element: (
            <PageWithMeta name={"assignment"}>
              <ViewAssignment />
            </PageWithMeta>
          ),
        },
        {
          path: routes.students.view,
          element: (
            <PageWithMeta name={"student"}>
              <ViewStudent />
            </PageWithMeta>
          ),
        },
        {
          path: routes.submission.view,
          element: (
            <PageWithMeta name={"submission"}>
              <ViewSubmission />
            </PageWithMeta>
          ),
        },
        {
          path: routes.classes.home,
          element: (
            <PageWithMeta name={"classes"}>
              <Lectures />
            </PageWithMeta>
          ),
        },
        {
          path: routes.classes.class,
          element: (
            <PageWithMeta name={"lecture"}>
              <ViewLecture />
            </PageWithMeta>
          ),
        },
        {
          path: oldRoutes.dashboard,
          // old routes redirects
          element: <Outlet />,
          children: [
            {
              path: oldRoutes.home,
              element: <DynamicRedirect to={routes.assignment.list} />,
            },
            {
              path: oldRoutes.assignment.list,
              element: <DynamicRedirect to={routes.assignment.list} />,
            },
            {
              path: oldRoutes.assignment.view,
              element: <DynamicRedirect to={routes.assignment.view} />,
            },
            {
              path: oldRoutes.assignment.student,
              element: <DynamicRedirect to={routes.students.view} />,
            },
            {
              path: oldRoutes.students.list,
              element: <DynamicRedirect to={routes.students.list} />,
            },
            {
              path: oldRoutes.submission.view,
              element: <DynamicRedirect to={routes.submission.view} />,
            },
            {
              path: oldRoutes.classes.home,
              element: <DynamicRedirect to={routes.classes.home} />,
            },
            {
              path: oldRoutes.classes.class,
              element: <DynamicRedirect to={routes.classes.class} />,
            },
          ],
        },
      ],
    },
  ])

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalState>
        <ToastProvider />
        <RouterProvider router={router} />
      </GlobalState>
    </QueryClientProvider>
  )
}

export default App
