import { toast } from "@suraasa/placebo-ui"
import { APIError } from "api/utils"
import { format } from "date-fns"

export const getPlatformURL = (platform: "sso", url: string) => {
  switch (platform) {
    case "sso":
      return `${import.meta.env.VITE_SSO_URL}${url}`
    default:
      return url
  }
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>

export const formatDate = (
  date: string | undefined | null,
  formatStr = "MMMM d, yyyy"
) => (date ? format(new Date(date), formatStr) : "")

type GetRouteParams<T extends string> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends `${infer _start}:${infer Param}/${infer rest}`
    ? Param | GetRouteParams<`/${rest}`>
    : // eslint-disable-next-line @typescript-eslint/no-unused-vars
    T extends `${infer _start}:${infer Param}`
    ? Param
    : never

export type RouteParams<T extends string> = {
  [K in GetRouteParams<T>]: string
}
export const routeBuilder = <T extends string>(
  route: T,
  params: RouteParams<T>
): string => {
  let r = route as string
  for (const key in params) {
    r = r.replace(`:${key}`, params[key as keyof typeof params])
  }
  return r
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export const snakeToTitleCase = (
  string: string | undefined,
  upperCaseWords = true
) => {
  if (!string) {
    return ""
  }
  return string
    .toLowerCase()
    .split("_")
    .map(word => {
      return upperCaseWords
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word
    })
    .join(" ")
}

export function narrowError(e: unknown): e is APIError {
  return e instanceof APIError
}

export const handleErrors = (errors: any) => {
  if (!narrowError(errors)) {
    throw new Error(
      "Error supplied to mapErrors is not an instance of APIError. Throwing original error",
      errors
    )
  }

  const {
    errors: { message, fieldErrors },
  } = errors
  if (message) {
    toast.error(message)
  }

  // if there was no message, but there were fieldErrors without a setter, we need to show them to the user
  if (!message && fieldErrors) {
    const firstError = Object.entries(fieldErrors)[0]
    if (firstError) {
      const [k, v] = firstError
      toast.error(`Error on key '${k}'`, {
        description: typeof v === "string" ? v : JSON.stringify(v[0]),
      })
    }
  }
}
